
  import React from 'react';
  import COMPONENTS from '../../components';
  import { get } from 'lodash';
  import PageLayout from '../../layout';
  import { graphql } from 'gatsby';

  class Page extends React.Component {
    state = {
      data: [],
    };

    componentDidMount() {
      let slices;

      const data = get(this, 'props.data.corporatesJson');

      if (data) {
        slices = Object.entries(data).map(([key, content]) => ({
          componentName: key,
          ...content,
        }));

        slices.sort(
          ({ position: positionA }, { position: positionB }) =>
            positionA - positionB
        );

        this.setState({
          data: slices,
        });
      }
    }

    render() {
      return (
        <PageLayout>
          {get(this, 'state.data').map(({ componentName, data }, index) => {
            const ComponentType = COMPONENTS[componentName];
            return <ComponentType key={index} {...data} />;
          })}
        </PageLayout>
      );
    }
  }

  export default Page;

  export const pageQuery = graphql`
    query {
      corporatesJson {
        
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      CTAWith2Buttons0 {
        position
        componentName
        data {
           background title description buttonDark {  text link } buttonLight {  text link }
        }
      }
    
      BannerMask1 {
        position
        componentName
        data {
           media {  type src } corners height
        }
      }
    
      Divider2 {
        position
        componentName
        data {
           height
        }
      }
    
      CTAPhoto3 {
        position
        componentName
        data {
           image title textBody button {  text link }
        }
      }
    
      Divider4 {
        position
        componentName
        data {
           height
        }
      }
    
      LogoRoll5 {
        position
        componentName
        data {
           header iconGroup {  social }
        }
      }
    
      CTAColumn6 {
        position
        componentName
        data {
           title type description button {  text link }
        }
      }
    
      Divider7 {
        position
        componentName
        data {
           height
        }
      }
    
      SectionSeparator8 {
        position
        componentName
        data {
           sectionName
        }
      }
    
      Ventures9 {
        position
        componentName
        data {
           ventures {  title textBody imageBlock }
        }
      }
    
      Divider10 {
        position
        componentName
        data {
           height
        }
      }
    
      CTAPhoto11 {
        position
        componentName
        data {
           image title imageSide textBody button {  text link }
        }
      }
    
      Divider12 {
        position
        componentName
        data {
           height
        }
      }
    
      CTAPhoto13 {
        position
        componentName
        data {
           image title imageSide textBody button {  text link }
        }
      }
    
      CarouselTestimonials14 {
        position
        componentName
        data {
           quotes {  quote picture subtitle }
        }
      }
    
      CTAPhoto15 {
        position
        componentName
        data {
           image title imageSide textBody button {  text link }
        }
      }
    
      Divider16 {
        position
        componentName
        data {
           height
        }
      }
    
      CTAColumn17 {
        position
        componentName
        data {
           title type description button {  text link }
        }
      }
    
      Divider18 {
        position
        componentName
        data {
           height
        }
      }
    
      SectionSeparator19 {
        position
        componentName
        data {
           sectionName
        }
      }
    
      Article3Column20 {
        position
        componentName
        data {
           header articles {  link image date textBody }
        }
      }
    
      }
    }
  `